import * as React from 'react'
import { Link } from 'gatsby'
import {SEO} from '../components/seo'


// styles
const pageStyles = {
  color: '#232129',
  padding: '96px',
  fontFamily: '-apple-system, Roboto, sans-serif, serif',
}
const headingStyles = {
  marginTop: 0,
  marginBottom: 64,
  maxWidth: 320,
}

const paragraphStyles = {
  marginBottom: 48,
}
const codeStyles = {
  color: '#8A6534',
  padding: 4,
  backgroundColor: '#FFF4DB',
  fontSize: '1.25rem',
  borderRadius: 4,
}

// markup
const NotFoundPage = () => (
  <main style={pageStyles}>
    <SEO/> 
    <h1 style={headingStyles}>Page not found</h1>
    <p style={paragraphStyles}>
      Sorry
      {' '}
      <span role='img' aria-label='Pensive emoji'>
        😔
      </span>
      {' '}
      we couldn’t find what you were looking for.
      <br />
      <Link to='http://margaritasifnos.gr'>Go to our homepage</Link>
      .
    </p>
  </main>
)

export default NotFoundPage
